<template>
  <div>
    <search-form>
      <div class="d-inline-flex flex-wrap mb-3">
        <c-input v-model="search.id" label="ID" search-form />
        <auto-complete
          v-model="search.parentId"
          :search="organizationSearch"
          :select="organizationSelect"
          :label="$t('organizations.organization')"
          search-form
        />

        <c-input v-model="search.name" :label="$t('shared.name')" search-form />
        <c-input select v-model="search.active" :items="$booleanOptions" :label="$t('shared.active')" search-form />
        <date-picker v-model="search.created.min" search-form :label="$t('shared.createdMin')" />
        <date-picker v-model="search.created.max" search-form :label="$t('shared.createdMax')" />
        <c-input v-model="search.tel" :label="$t('shared.phone')" search-form />
        <c-input v-model="search.fax" :label="$t('shared.fax')" search-form />
        <c-input v-model="search.address" :label="$t('shared.address')" search-form />
      </div>

      <div class="ma-1">
        <v-btn color="success" dark @click="filtering" small :loading="progressing">
          <v-icon small class="me-1"> mdi-filter </v-icon>
          {{ $t('shared.applyFilter') }}
        </v-btn>
      </div>
    </search-form>

    <div class="d-flex mb-4">
      <v-spacer />
      <v-btn color="success" dark :to="{path: '/branches/create', query: {organizationId}}" small>
        <v-icon small> mdi-plus </v-icon>
        {{ $t('shared.add') }}
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="context"
      @update:options="filtering"
      :server-items-length="count"
      :loading="progressing"
      :footer-props="$config.tableFooterProps"
      sort-by="id"
      must-sort
      class="elevation-2"
    >
      <template #[`item.logo`]="{item}">
        <thumbnail :src="item.logo" height="40" />
      </template>

      <template #[`item.organization`]="{item}">
        <span v-if="item.parent">{{ item.parent.name }}</span>
      </template>

      <template #[`item.active`]="{item}">
        <v-icon v-if="item.active" color="success"> mdi-check </v-icon>
        <v-icon v-else color="secondary"> mdi-close </v-icon>
      </template>

      <template #[`item.actions`]="{item}">
        <div class="d-flex justify-end">
          <v-tooltip v-if="$hasPermission('identity.roles')" top>
            <template v-slot:activator="{on}">
              <v-btn small fab text color="warning" :to="{path: '/roles/index', query: {branchId: item.id}}" v-on="on">
                <v-icon> mdi-security </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('nav.roles') }}</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn small fab text color="info" :to="`/branches/edit/${item.id}`" v-on="on">
                <v-icon> mdi-eye </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('shared.view') }}</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn small fab text color="error" @click="removeItem(item)" v-on="on">
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('shared.delete') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:no-data>
        {{ $t('shared.noRecords') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'IndexPage',
  props: {
    organizationId: {type: [String, Number], default: null},
    branch: {type: Boolean, default: false}
  },
  data() {
    return {
      progressing: false,
      count: 1,
      context: {},
      items: [],
      headers: [
        {text: 'ID', value: 'id', width: 100},
        {text: this.$t('shared.logo'), value: 'logo', width: 100},
        {text: this.$t('shared.name'), value: 'name'},
        {text: this.$t('organizations.organization'), value: 'organization'},
        {text: this.$t('shared.phone'), value: 'tel'},
        {text: this.$t('shared.active'), value: 'active'},
        {text: this.$t('shared.actions'), value: 'actions', sortable: false}
      ],
      search: {
        id: null,
        parentId: null,
        name: null,
        active: null,
        tel: null,
        fax: null,
        address: null,
        created: {
          min: null,
          max: null
        }
      }
    }
  },
  async mounted() {
    const {organizationId} = this.$route.query
    if (organizationId) {
      this.search.parentId = +organizationId
      await this.filtering()
    }
  },
  methods: {
    async organizationSelect() {
      const {organizationId} = this.$route.query
      if (organizationId) {
        this.search.parentId = +organizationId
        return this.$api.branches.getById(organizationId)
      }
      return Promise.resolve({})
    },
    async organizationSearch(filter) {
      filter.parent = true
      return this.$api.branches.getAll(filter)
    },
    filter(isCount) {
      const filter = this.$filter()

      if (!filter.parentId) {
        filter.parent = false
      }

      if (!isCount) {
        filter['sort[0].column'] = this.context.sortBy[0]
        filter['sort[0].type'] = this.context.sortDesc[0] ? 'ASCENDING' : 'DESCENDING'
        filter.size = this.context.itemsPerPage
        filter.page = this.context.page
      }

      return filter
    },
    async getItems(filter) {
      return this.$api.branches.getAll(filter, this.type).then((res) => {
        this.items = res
      })
    },
    async getCount(filter) {
      return this.$api.branches.count(filter, this.type).then((res) => {
        this.count = res
      })
    },
    async filtering() {
      this.progressing = true
      Promise.all([this.getItems(this.filter()), this.getCount(this.filter(true))])
        .then(() => {
          this.progressing = false
        })
        .catch((err) => {
          this.$showError(err)
        })
    },
    async removeItem(item) {
      try {
        if (!(await this.$confirm())) return

        await this.$api.branches.delete(item.id)
        this.filtering()
        this.$showSuccess(this.$t('branches.branchRemoved'))
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>
